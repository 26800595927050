import React, { useState, useEffect } from 'react';
import { Sandpack } from "@codesandbox/sandpack-react";
import { amethyst } from "@codesandbox/sandpack-themes";
import './App.css';

function App() {
  const [versionVisible, setVersionVisible] = useState(false);
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [inputData, setInputData] = useState('');
  const [useSearch, setUseSearch] = useState(false);
  const [attachFiles, setAttachFiles] = useState(false);
  const [model, setModel] = useState('orquestador');
  const [sandpackFiles, setSandpackFiles] = useState(null);
  const [promptSent, setPromptSent] = useState(false);
  const [loading, setLoading] = useState(false); // Para manejar la animación de carga
  const texts = [
    "Procesando tu instrucción",
    "Escogiendo la tecnología",
    "Generando un diseño único",
    "Formulando Algoritmos",
    "Programando",
    "Solucionando bugs",
    "Tu web está casi lista",
  ];
  const [currentText, setCurrentText] = useState(texts[0]);
  const [textIndex, setTextIndex] = useState(0);

  // Lógica del Beta Version Toggle
  const toggleVersionInfo = () => {
    setVersionVisible(!versionVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const versionInfo = document.getElementById('versionInfo');
      const betaButton = document.getElementById('betaButton');
      if (versionInfo && betaButton && !versionInfo.contains(event.target) && !betaButton.contains(event.target)) {
        setVersionVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Lógica del Feedback Modal
  const openFeedback = () => {
    setFeedbackVisible(true);
  };

  const closeFeedback = () => {
    setFeedbackVisible(false);
  };

  const handleFeedbackClickOutside = (event) => {
    if (event.target.className === 'feedback-modal') {
      closeFeedback();
    }
  };

  // Lógica del Textarea Auto-resize
  useEffect(() => {
    const textarea = document.querySelector('.auto-resize');
    if (textarea) {
      textarea.addEventListener('input', function () {
        this.style.height = 'auto';
        this.style.height = this.scrollHeight + 'px';
      });
    }
  }, []);

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }, 4000); // Cambia de frase cada 4 segundos

      return () => clearInterval(intervalId); // Limpia el intervalo al desmontar
    }
  }, [loading, texts.length]);

  useEffect(() => {
    setCurrentText(texts[textIndex]);
  }, [textIndex, texts]);

  // Función para manejar el envío de datos
  const sendData = () => {
    if (!inputData) {
      alert('Por favor ingresa un dato para generar.');
      return;
    }

    // Mostrar la animación de carga
    setLoading(true);

    // Limpiar el código del Sandpack y mostrar "Generando..."
    setSandpackFiles({
      "/index.html": {
        code: '// Generando...\n',
        hidden: false,
      }
    });

    // Simular un POST al servidor con un tiempo de espera
    setTimeout(() => {
      // Aquí iría la lógica de envío del POST real al servidor
      fetch('http://api.xtarify.com/api/chat/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt: inputData,
          use_search: useSearch,
          attach_files: attachFiles,
          model: model,
        }),
        mode: 'cors',
      })
        .then((response) => response.json())
        .then((result) => {
          let responseText = result.response || '// No se pudo obtener la respuesta de la IA';
          // Actualizar el código que se muestra en Sandpack con la respuesta de la IA
          setSandpackFiles({
            "/index.html": {
              code: responseText,
              hidden: false,
            }
          });
          setPromptSent(true);
          setLoading(false); // Detener la animación cuando el servidor responde
        })
        .catch((error) => {
          console.error('Error:', error);
          alert('Hubo un error al enviar los datos');
          setLoading(false); // Detener la animación si hay un error
        });
    }, 5000); // Simula un retraso de 5 segundos en la respuesta
  };

  // Enviar el POST al presionar Enter
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendData();
    }
  };

  return (
    <div>
      

      {/* Header */}
      <header className="header">
        <a href="/" className="home-link">
          <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="Xtarify Logo" className="logo" />
          <span className="site-title">Xtarify</span>
        </a>
      </header>

      {/* Background Video */}
      <div className="video-background">
        <video autoPlay loop muted>
          <source src={`${process.env.PUBLIC_URL}/assets/background.mp4`} type="video/mp4" />
        </video>
      </div>

      {/* Beta Version Info */}
      <div className="beta-version" id="betaButton" onClick={toggleVersionInfo}>
        Beta v0.5
      </div>
      {versionVisible && (
        <div className="version-info" id="versionInfo">
          <h2 className="updates-title">Updates</h2>
          <div className="updates">
            <div className="update-box">
              <strong>v0.5 coming...</strong>
              <p>Acepta img y docs</p>
            </div>
            {/* Agregar más versiones aquí */}
          </div>
        </div>
      )}

      {/* Feedback Modal */}
      <button id="feedbackButton" className="feedback-btn" onClick={openFeedback}>
        Feedback
      </button>
      {feedbackVisible && (
        <div className="feedback-modal" id="feedbackModal" onClick={handleFeedbackClickOutside}>
          <div className="modal-content">
            <span className="close-btn" onClick={closeFeedback}>
              &times;
            </span>
            <h2 className="modal-title">Déjanos tu feedback</h2>
            <textarea className="feedback-textarea" placeholder="Escribe aquí tu feedback..."></textarea>
            <button className="submit-feedback-btn">Enviar</button>
          </div>
        </div>
      )}

      {/* Loading Animation */}
      {loading ? (
        <div style={styles.waveTextContainer}>
          <h1 style={{ color: '#fff' }}>Generating your next big project</h1>
          <div
            id="changing-text"
            className="wave-text animating" // Aplica clase animada
            data-text={currentText}
            style={{ color: '#fff' }}
          >
            {currentText}
          </div>
        </div>
      ) : (
        <>
          {/* Input and Button to Generate Code (Visible solo antes de enviar prompt) */}
          {!promptSent && (
            <div className="content">
              <h1>
                What can I help you <span className="highlight-blue">build</span> today?
              </h1>
              <h3 className="small-text">
                Generate websites, ask questions, remember better prompt, better results .
              </h3>
              <div className="input-group">
                <textarea
                  className="auto-resize"
                  placeholder="Generate a full website..."
                  value={inputData}
                  onChange={(e) => setInputData(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button className="try-button" onClick={sendData}>
                →
                </button>
              </div>

              <div className="options-group">
                <label className="option-checkbox">
                  <input
                    type="checkbox"
                    checked={useSearch}
                    onChange={() => setUseSearch(!useSearch)}
                  />
                  Search online?
                </label>
                <label className="option-checkbox">
                  <input
                    type="checkbox"
                    checked={attachFiles}
                    onChange={() => setAttachFiles(!attachFiles)}
                  />
                  Upload Files?
                </label>
              </div>

              <div className="model-selection">
                <label htmlFor="model-select">AI Technology:</label>
                <select
                  id="model-select"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                >
                  <option value="orquestador">Orquestador</option>
                  <option value="xtarify-experimental" disabled>
                    Xtarify Experimental (in development)
                  </option>
                  <option value="moa" disabled>
                    Methodology MoA (in development)
                  </option>
                </select>
              </div>
            </div>
          )}

          {/* Sandpack Preview and Input (Visible solo después de enviar prompt) */}
          {promptSent && (
            <div className="sandpack-container" style={{ width: '100%', margin: '0px auto' }}>
              <Sandpack
                theme={amethyst}
                template="static"
                files={sandpackFiles}
                options={{
                  showNavigator: true,
                  showTabs: true,
                  editorHeight: 700, // Altura predefinida de 700px
                }}
                style={{
                  height: '700px',
                  width: '100%',
                  maxWidth: '100%',
                }}
              />
              <div className="input-group2" style={{ marginTop: '20px' }}>
                <textarea
                  className="auto-resize"
                  placeholder="Genera una página web..."
                  value={inputData}
                  onChange={(e) => setInputData(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button className="try-button2" onClick={sendData}>
                  →
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {/* Footer */}
      <footer className="footer">
        <a href="/faq" className="footer-button">
          FAQ
        </a>
        <a href="/ai-policy" className="footer-button">
          AI Policy
        </a>
        <a href="/nosotros" className="footer-button">
          Nosotros
        </a>
      </footer>

      {/* Redes Sociales */}
      <div className="social-buttons">
        {/* LinkedIn */}
        <a href="https://www.linkedin.com/company/xtarify/" target="_blank" rel="noopener noreferrer" className="social-button linkedin-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="36" height="36" fill="currentColor">
            <path fill="currentColor" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
          </svg>
        </a>

        {/* Discord */}
        <a href="https://discord.com" target="_blank" rel="noopener noreferrer" className="social-button discord-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="36" height="36" fill="currentColor">
            <path fill="currentColor" d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path>
          </svg>
        </a>

        {/* Facebook */}
        <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" className="social-button facebook-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="36" height="36" fill="currentColor">
            <path fill="currentColor" d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
          </svg>
        </a>
      </div>
    </div>
  );
}

const styles = {
  waveTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'transparent', // Fondo transparente para usar el fondo del video
    color: '#fff', // Cambiar color del texto a blanco
  },
};

export default App;
